import React from "react";

import { Container, Row, Col } from "react-bootstrap";

function Socials() {
  const centerContent = {
    textAlign: "center",
  };
  return (
    <Container fluid>
      <Row style={centerContent} className="p-2">
        <Col>
          <a
            href="https://github.com/mo-hobbs"
            target="_blank"
            rel="noopener noreferrer"
          >
            <span className="fa-brands fa-github fa-xl p-2"></span>
          </a>

          <a
            href="https://www.linkedin.com/in/morganlhobbs/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <span className="fa-brands fa-linkedin fa-xl p-2"></span>
          </a>

          <a
            href="https://mohobbs.blogspot.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <span className="fa-brands fa-blogger fa-xl p-2"></span>
          </a>

          <a href="https://www.morganmoonchild.com" target="_blank" rel="noopener noreferrer">
            <span className="fa-solid fa-globe fa-xl p-2"></span>
          </a>
          <a href="mailto:mohobbs@gmail.com">
            <span className="fa-solid fa-envelope fa-xl p-2"></span>
          </a>
        </Col>
      </Row>
    </Container>
  );
}
export default Socials;
