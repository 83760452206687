import React from "react";

import { Container, Row, Col, Button } from "react-bootstrap";

import arrow from "./images/arrow-icon.png";

function Contact() {
  const contactStyle = {
    fontSize: "72px",
  };

  return (
    <Container fluid id="contact">
      <Row className="p-3">
        <h1 className="mb-5" style={contactStyle}>
          GET IN TOUCH
        </h1>
        <p>
          {" "}
          Whether you're looking for web design or a consultation on software
          development drop me a line and I'll let you know how I can help.
        </p>
      </Row>

      <Row>
        <Col md={{ offset: 6 }}>
          <img
            src={arrow}
            className="me-4"
            alt="arrow icon pointing to Say Hello Button"
          />
          <a
            href="mailto:mohobbs@gmail.com?subject=hello!"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Button
              style={{
                backgroundColor: "#FFE7CF",
                color: "#3A505F",
                border: "#3A505F",
                padding: "1em",
                width: "50%",
                fontWeight: "bold",
              }}
            >
              Say Hello
            </Button>
          </a>
        </Col>
      </Row>
    </Container>
  );
}

export default Contact;
