import React from "react";

import { Container, Card, CardGroup, Row } from "react-bootstrap";

function Experience() {
  const cardStyle = {
    background: "transparent",
    border: "#3A505F",
  };
  const headerStyle = {
    background: "transparent",
  };
  return (
    <Container fluid className="p-3" id="experience">
      <h1>EXPERIENCE</h1>
      <br></br>
      <Row sm={1} className="g-4">
        <CardGroup>
          <Card style={cardStyle}>
            <Card.Title>LANGUAGES</Card.Title>
            <Card.Header style={headerStyle}></Card.Header>
            <Card.Body>
              <Card.Text>
                <p>TypeScript / JavaScript</p>
                <p>Ruby</p>
                <p>Sinatra</p>
                <p>HTML</p>
                <p>CSS</p>
                <p>SQL</p>
              </Card.Text>
            </Card.Body>
          </Card>

          <Card style={cardStyle}>
            <Card.Title>FRAMEWORKS</Card.Title>
            <Card.Header style={headerStyle}></Card.Header>
            <Card.Body>
              <Card.Text>
                <p>React</p>
                <p>Rails</p>
                <p>Ionic</p>
                <p>Node.js</p>
                <p>Postgresql</p>
                <p>Firebase</p>
              </Card.Text>
            </Card.Body>
          </Card>

          <Card style={cardStyle}>
            <Card.Title>TOOLS</Card.Title>
            <Card.Header style={headerStyle}></Card.Header>
            <Card.Body>
              <Card.Text>
                <p>GitHub</p>
                <p>Visual Studio Code</p>
                <p>Zenhub / JIRA</p>
                <p>Capacitor</p>
                <p>Heroku</p>
                <p>Chrome DevTools</p>
              </Card.Text>
            </Card.Body>
          </Card>

          <Card style={cardStyle}>
            <Card.Title>DESIGN</Card.Title>
            <Card.Header style={headerStyle}></Card.Header>
            <Card.Body>
              <Card.Text>
                <p>Adobe Illustrator</p>
                <p>Adobe Photoshop</p>
                <p>Canva</p>
                <p>Figma</p>
                <p>Wireframing</p>
              </Card.Text>
            </Card.Body>
          </Card>
        </CardGroup>
      </Row>
    </Container>
  );
}
export default Experience;
