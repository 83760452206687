import React from "react";
import { Container, Card, CardGroup } from "react-bootstrap";

import waterfall_goofy from "./images/waterfall-goofy.jpeg";

function About() {
  const cardStyle = {
    background: "transparent",
    border: "#3A505F",
  };
  return (
    <Container fluid className="p-3" id="about">
      <h1>ABOUT ME</h1>
      <CardGroup>
        <Card style={cardStyle}>
          <Card.Body>
            <Card.Img
              src={waterfall_goofy}
              alt="photo of Morgan in front of a waterfall making a silly pose"
            />
          </Card.Body>
        </Card>

        <Card style={cardStyle}>
          <Card.Body>
            <Card.Text>
              <p>
                Hi, I'm Morgan!{" "}
                <span role="img" aria-label="Wave">
                  👋🏻
                </span>
              </p>
              <p>I'm a Fullstack Software Engineer and Web Developer.</p>
              <p>
                I'm passionate about building software and web applications 
                to make the world a better place. I strive to be
                authentic, kind, and an empathetic listener as I work to
                understand problems and craft solutions.{" "}
              </p>
              <p>
                I am a mission-driven, heart-centered, tech nerd based outside
                Salt Lake City, Utah. In my free time I love to play outside or geek
                out on learning new things about space, birds, and plants.
              </p>
            </Card.Text>
          </Card.Body>
        </Card>
      </CardGroup>
    </Container>
  );
}
export default About;
