import React from "react";
import { Navbar, Nav, Container } from "react-bootstrap";

import logo from "./images/portfolio-logo-500x500.png"

function Header() {
  const headerStyle = {
    color: "#FFE7CF",
    fontSize: "18px",
    textTransform: "uppercase",
  };


  return (
    <Container fluid>

     
      <Navbar collapseOnSelect expand="md">

      <Navbar.Brand href="#home">
        <img
          src={logo}
          width="50px"
          height="50px"
          
          href="#home"
          alt="Morgan Portfolio logo"
        />
      </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="ms-auto" style={headerStyle}>
            <Nav.Link style={headerStyle} href="#about">
              About
            </Nav.Link>
            <Nav.Link style={headerStyle} href="#services">
              Services
            </Nav.Link>
            <Nav.Link style={headerStyle} href="#portfolio">
              Portfolio
            </Nav.Link>
            <Nav.Link style={headerStyle} href="#experience">
              Experience
            </Nav.Link>
            <Nav.Link
              style={headerStyle}
              href="https://drive.google.com/file/d/1rapd8KosuNAVrv9FicCZ1LRnymfn4ZFN/view?usp=sharing"
              target="_blank"
              rel="noreferrer noopener"
            >
              Resume
            </Nav.Link>
            <Nav.Link style={headerStyle} href="#contact">
              Contact
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Navbar>

    </Container>
  );
}
export default Header;
