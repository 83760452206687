import React from "react";

import Socials from "./Socials";

import { Container, Button, CardGroup, Card, Row } from "react-bootstrap";

import welcome_photo from "./images/welcome-page-photo-600x800.png";

function Welcome() {
  const cardStyle = {
    background: "transparent",
    border: "#3A505F",
    textAlign: "center",
  };

  return (
    <Container fluid className="p-3" id="home">
      <Row>
        <CardGroup>
          <Card style={cardStyle} className="pt-5 pb-5">
            <Card.Body className="pt-5 pb-5">
              <Card.Title style={{ fontSize: "3em" }}>MORGAN HOBBS</Card.Title>
              <br></br>
              <Card.Subtitle>FULLSTACK SOFTWARE ENGINEER</Card.Subtitle>
              <Card.Text>
                <br></br>
                <p>Building products to improve people's lives</p>
                <br></br>
                <Socials />
                <br></br>
                <a
                  href="mailto:mohobbs@gmail.com?subject=hello!"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Button
                    style={{
                      backgroundColor: "#FFE7CF",
                      color: "#3A505F",
                      border: "#3A505F",
                      width: "50%",
                      fontWeight: "bold",
                    }}
                  >
                    Let's Connect
                  </Button>
                </a>
              </Card.Text>
            </Card.Body>
          </Card>

          <Card style={cardStyle}>
            <Card.Img
              src={welcome_photo}
              alt="photo of Morgan with a huge smile on her face"
            />
          </Card>
        </CardGroup>
      </Row>
    </Container>
  );
}
export default Welcome;
