import React from "react";

import trebexia from "./images/trebexia.png";
import moonchild from "./images/moonchild.png";
// import mastermind from "./images/mastermind.png";
// import snftr from "./images/snftr.png";

import { Container, Card, CardGroup } from "react-bootstrap";

function Portfolio() {
  const linkStyle = {
    color: "#ddc8b3",
  };
  const cardStyle = {
    background: "transparent",
    border: "#3A505F",
  };
  return (
    <Container fluid className="p-3" id="portfolio">
      <h1>PORTFOLIO</h1>
      <CardGroup>
        <Card style={cardStyle} className="me-5">
          <Card.Img src={moonchild} alt="photo of Morgan Moonchild Website" onClick={()=> window.location.href = "https://www.morganmoonchild.com/"}/>
          <Card.Body>
            <Card.Title>Morgan Moonchild</Card.Title>
            <Card.Text>
              <p>
                E-commerce platform with data syncing to an Etsy shop. Created
                all design elements including logo, branding, photography, and
                UI.
              </p>
              <a href="https://www.morganmoonchild.com/" target="_blank" rel="noopener noreferrer" >
                <i className="fa-solid fa-arrow-up-right-from-square fa-xl p-1"></i>
              </a>
              <a
                href="https://github.com/mo-hobbs/morgan-moonchild"
                target="_blank" rel="noopener noreferrer" 
              >
                <i className="fa-brands fa-github fa-xl p-1"></i>
              </a>
            </Card.Text>
          </Card.Body>
        </Card>
        <Card style={cardStyle} className="me-5">
          <Card.Img src={trebexia} alt="trebexia logo" onClick={()=> window.location.href = "https://trebexia.netlify.app/" }/>
          <Card.Body>
            <Card.Title>Trebexia Trivia</Card.Title>
            <Card.Text>
              <p>
                Fullstack web app to compete with your friends on trivia. Built
                as a pair programming project using the{" "}
                <a
                  style={linkStyle}
                  href="https://opentdb.com/"
                  target="_blank" rel="noopener noreferrer" 
                >
                  Open Trivia Database
                </a>{" "}
                API with login authentication, sessions, and cookies.
              </p>
            </Card.Text>
            <a href="https://trebexia.netlify.app/" target="_blank" rel="noopener noreferrer" >
              <i className="fa-solid fa-arrow-up-right-from-square fa-xl p-1"></i>
            </a>
            <a
              href="https://github.com/mo-hobbs/morgan-moonchild"
              target="_blank" rel="noopener noreferrer" 
            >
              <i className="fa-brands fa-github fa-xl p-1"></i>
            </a>
          </Card.Body>
        </Card>
      </CardGroup>
      {/* <CardGroup>
        <Card style={cardStyle} className="me-5">
          <Card.Img src={mastermind} alt="photo of Mastermind Website" />
          <Card.Body>
            <Card.Title>Mastermind</Card.Title>
            <Card.Text>
              <p>
              An online version of the classic code-breaking game where two players can compete online and determine who is the master and who is the mind. 
              </p>
              <a href="http://www.morganmoonchild.com/" target="_blank" rel="noopener noreferrer" >
                <i className="fa-solid fa-arrow-up-right-from-square fa-xl p-1"></i>
              </a>
              <a
                href="https://github.com/mo-hobbs/morgan-moonchild"
                target="_blank" rel="noopener noreferrer" 
              >
                <i className="fa-brands fa-github fa-xl p-1"></i>
              </a>
            </Card.Text>
          </Card.Body>
        </Card>
        <Card style={cardStyle} className="me-5">
          <Card.Img src={snftr} alt="trebexia logo" />
          <Card.Body>
            <Card.Title>SNFTR</Card.Title>
            <Card.Text>
              <p>
                The best place to find, save, and share gifs (on the entire internet).
              </p>
            </Card.Text>
            <a href="https://trebexia.herokuapp.com/" target="_blank" rel="noopener noreferrer" >
              <i className="fa-solid fa-arrow-up-right-from-square fa-xl p-1"></i>
            </a>
            <a
              href="https://github.com/mo-hobbs/morgan-moonchild"
              target="_blank" rel="noopener noreferrer" 
            >
              <i className="fa-brands fa-github fa-xl p-1"></i>
            </a>
          </Card.Body>
        </Card>
      </CardGroup> */}
    </Container>
  );
}
export default Portfolio;
