import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import Welcome from "./Welcome";
import About from "./About";
import Services from "./Services";
import Portfolio from "./Portfolio";
import Experience from "./Experience";
import Contact from "./Contact";

import "bootstrap/dist/css/bootstrap.css";

import { Container } from "react-bootstrap";

function App() {
  return (
    <Container fluid >
      <Header style={{ color: "#FFE7CF" }} />
      <Welcome />
      <About />
      <Services />
      <Portfolio />
      <Experience />
      <Contact />
      <Footer />
    </Container>
  );
}

export default App;
