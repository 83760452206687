import React from "react";

import skills from "./images/skills-720x400.png";
import technical from "./images/technical-720x400.png";

import { Container, Card, CardGroup } from "react-bootstrap";

function Services() {
  const cardStyle = {
    background: "transparent",
    border: "#3A505F",
  };
  return (
    <Container fluid className="p-3" id="services">
      <h1>SERVICES</h1>

      <CardGroup>
        <Card style={cardStyle} className="me-5">
          <Card.Img
            src={skills}
            alt="three hands each holding coffee cups and putting cups together to say cheers"
          />

          <Card.Body>
            <Card.Title>Skills</Card.Title>
            <Card.Text>
              <ul>
                <li>Passionate, creative, and curious problem solver.</li>
                <li>
                  Charismatic, emotionally intelligent leader comfortable
                  collaborating with diverse groups to solve creative and
                  technical challenges.
                </li>

                <li>
                  Data-driven decision maker with a background in retail,
                  fundraising, and nonprofit administration.
                </li>
              </ul>
            </Card.Text>
          </Card.Body>
        </Card>

        <Card style={cardStyle} className="me-5">
          <Card.Img src={technical} alt="colorful React code"/>

          <Card.Body>
            <Card.Title>Technical</Card.Title>
            <Card.Text>
              <ul>
                <li>Data management and backend support </li>
                <li>Web Development</li>

                <li>Application Development</li>
                <ul>
                  <li>iOS</li>
                  <li>Android</li>
                </ul>
              </ul>
            </Card.Text>
          </Card.Body>
        </Card>
      </CardGroup>
    </Container>
  );
}

export default Services;
