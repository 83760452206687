import React from "react";
import { Container, Row } from "react-bootstrap";

import Socials from "./Socials";

function Footer() {
  const centerContent = {
    textAlign: "center",
  };
  return (
    <Container fluid className="p-5">
      <Socials />

      <Row style={centerContent}>
        <p>© 2023 Morgan Hobbs</p>
      </Row>
    </Container>
  );
}
export default Footer;
